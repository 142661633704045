import React from "react"
import styled from "@emotion/styled"

import { SmallHeroLayout } from "../components/layouts"
import Image from "../components/generic/image"
import SEO from "../components/seo"

const StudiosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  h1,
  h2,
  p {
    width: 100%;
    text-align: left;
    padding: 0 2rem;
  }

  & > * {
    max-width: 48rem;
  }

  @media screen and (min-width: 920px) {
    h1,
    h2,
    p {
      padding: 0;
    }
  }
`

const ImgContainer = styled.div`
  width: 100%;
  margin: 2rem 0;

  &:first-of-type {
    margin-top: 0.5rem;
  }

  @media screen and (min-width: 920px) {
    min-width: 50%;
    margin-left: 1rem;
    margin-right: 1rem;
    border: solid 1px rgba(0, 0, 0, 0.25);
  }
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 920px) {
    flex-direction: row;
    align-items: center;

    &:first-of-type {
      flex-direction: row-reverse;
    }
  }
`

const MiddleRowContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 920px) {
    margin: 3rem 0;
    align-items: center;
    justify-content: center;

    h1 {
      order: 0;
      text-align: center;
      margin-bottom: 1rem;
    }

    ${ImgContainer} {
      order: 1;
      max-width: 36rem;
      margin-bottom: 1rem;
    }

    p {
      order: 2;
      max-width: 34rem;
    }
  }
`

const StudiosPage = () => (
  <SmallHeroLayout pageTitle="Studios">
    <SEO title="Studios" />
    <StudiosContainer>
      <RowContainer>
        <ImgContainer>
          <Image src="/assets/images/StudiosExternal.jpg" />
        </ImgContainer>
        <div>
          <h1>Our studios</h1>
          <p>
            Six Strings Townsville is a local family run business with four
            studio rooms on premise.
          </p>
        </div>
      </RowContainer>

      <MiddleRowContainer>
        <ImgContainer>
          <Image src="/assets/images/StudiosInternal.jpg" />
        </ImgContainer>
        <h1>Private and comfy</h1>
        <p>
          The studio spaces are freshly renovated, sound-proof and have been
          fitted out with high quality furnishings so you can practice in
          comfort and style.
        </p>
      </MiddleRowContainer>

      <RowContainer>
        <ImgContainer>
          <Image src="/assets/images/DamienGuitarSolo.jpg" />
        </ImgContainer>
        <div>
          <h1>Flexible teaching style</h1>
          <p>
            Learn in a way that you will enjoy with our unique Six Strings
            Townsville curriculum. We've developed it in-house, to be modern,
            fresh and fun, without compromising on all the important stuff.
          </p>
        </div>
      </RowContainer>

      {/* TODO: "Where are our studios located?" (night mode google map) */}
    </StudiosContainer>
  </SmallHeroLayout>
)

export default StudiosPage
